import { CRUD_PRODUCT_DISCOUNT, ERROR_MESSAGE, FETCH_PRODUCT_DISCOUNT, LOADING_BEGIN, PRODUCT_DISCOUNT_ID } from './constants';

const initialState = {
  errorMessage: '',
  loading: false,
  productDiscount: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  productDiscountId: {
    defaultPrice: 0,
    discount: 0,
    discountedPrice: 0,
    endDate: '',
    id: '',
    maxItem: 0,
    productName: '',
    sku: '',
    startDate: '',
  },
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_DISCOUNT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        productDiscount: action.payload
      };
    case PRODUCT_DISCOUNT_ID:
      return {
        ...state,
        productDiscountId: action.payload,
      };
    case LOADING_BEGIN:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CRUD_PRODUCT_DISCOUNT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success,
      };
    default:
      return state;
  }
}
