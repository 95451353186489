import React from 'react';
// import { ICONS } from '../../../configs';
import { Button } from '../../elements';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const ModalDeleteDisc = (props) => {
  return (
    <div className={styles['modal-delete-stock']}>
      <h4>Are you sure you want to stop this product discount?</h4>
      <div className="d-flex">
        <Button children="Cancel" className={styles['btn-ok']} onClick={props.onCancel} />
        <Button children="Stop" className={styles['btn-cancel']} onClick={props.onDelete} />
      </div>
    </div>
  );
};

export default ModalDeleteDisc;

ModalDeleteDisc.defaultProps = {
  onCancel: null,
  onDelete: null,
};

ModalDeleteDisc.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};
