import React from 'react';
import { useSelector } from 'react-redux';
import { ICONS, IMAGES } from '../../../configs';
import { useAuthorization } from '../../../pages/Auth/actions';
import { MenuSidebar, SubMenuSidebar } from '../../fragments';
import styles from './styles.module.scss';
import { AiOutlineFieldTime } from 'react-icons/ai';

const Sidebar = () => {
  const { Logout } = useAuthorization();
  const { user } = useSelector(state => state.dashboard);
  const _checkActive = (path) => {
    return window.location.pathname.includes(path);
  };

  return (
    <div className={['col-2 p-0', styles['sidebar']].join(' ')}>
      <div>
        <div className={['d-flex justify-content-center align-items-center', styles['wrap-toggle']].join(' ')}>
          <ICONS.LOGOSWADELIDASHBOARD />
        </div>
        <div className={styles['menu-container']}>
          <MenuSidebar
            checkActive={_checkActive('dashboard')}
            icon={<ICONS.DASHBOARD className={styles['icon']} />}
            router="/dashboard"
            subMenu={[]}
            title="Dashboard"
          />
          <SubMenuSidebar
            checkActive={_checkActive('product') || _checkActive('supplier') || _checkActive('hub') || _checkActive('category') || _checkActive('banner')}
            icon={<ICONS.MASTERDATA className={styles['icon']} />}
            subMenu={[
              {
                checkActive: _checkActive('product'),
                router: '/product',
                title: 'Product',
              },
              {
                checkActive: _checkActive('supplier'),
                router: '/supplier',
                title: 'Supplier',
              },
              {
                checkActive: _checkActive('hub'),
                router: '/hub',
                title: 'Hub',
              },
              {
                checkActive: _checkActive('category'),
                router: '/category',
                title: 'Category',
              },
              {
                checkActive: _checkActive('banner'),
                router: '/banner',
                title: 'Banner',
              },
            ]}
            title="Master Data"
          />
          <MenuSidebar
            checkActive={_checkActive('inbound')}
            icon={<ICONS.INBOUND className={styles['icon']} />}
            router="/inbound"
            subMenu={[]}
            title="Inbound"
          />
          <MenuSidebar
            checkActive={_checkActive('outbound')}
            icon={<ICONS.OUTBOUND className={styles['icon']} />}
            router="/outbound"
            subMenu={[]}
            title="Outbound"
          />
          <MenuSidebar
            checkActive={_checkActive('inventory')}
            icon={<ICONS.INVENTORY className={styles['icon']} />}
            router="/inventory"
            subMenu={[]}
            title="Inventory"
          />
          <SubMenuSidebar
            checkActive={_checkActive('referral-code-config') || _checkActive('product-discount')}
            icon={<ICONS.PROMOTION className={styles['icon']} />}
            subMenu={[
              {
                checkActive: _checkActive('referral-code-config'),
                router: '/referral-code-config',
                title: 'Referral Code Config'
              },
              {
                checkActive: _checkActive('product-discount'),
                router: '/product-discount',
                title: 'Product Discount'
              }
            ]}
            title="Promotion"
          />
          <SubMenuSidebar
            checkActive={_checkActive('transfer') || _checkActive('adjustment')}
            icon={<ICONS.ACTIVITY className={styles['icon']} />}
            subMenu={[
              {
                checkActive: _checkActive('transfer'),
                router: '/transfer',
                title: 'Transfer',
              },
              {
                checkActive: _checkActive('adjustment'),
                router: '/adjustment',
                title: 'Inventory Adjustment',
              },
            ]}
            title="Extra Activity"
          />
          <SubMenuSidebar
            checkActive={_checkActive('order') || _checkActive('transaction') || _checkActive('shipment')}
            icon={<ICONS.REPORTING className={styles['icon']} />}
            subMenu={[
              {
                checkActive: _checkActive('order'),
                router: '/order',
                title: 'Order',
              },
              {
                checkActive: _checkActive('transaction'),
                router: '/transaction',
                title: 'Transaction',
              },
              {
                checkActive: _checkActive('shipment'),
                router: '/shipment',
                title: 'Shipment',
              }
            ]}
            title="Reporting"
          />
          <SubMenuSidebar
            checkActive={_checkActive('admin') || _checkActive('driver')}
            icon={<ICONS.USERMANAGEMENT className={styles['icon']} />}
            subMenu={[
              {
                checkActive: _checkActive('admin'),
                router: '/admin',
                title: 'Admin',
              },
              // {
              //   checkActive: _checkActive('customer'),
              //   router: '/customer',
              //   title: 'Customer',
              // },
              {
                checkActive: _checkActive('driver'),
                router: '/driver',
                title: 'Driver',
              },
            ]}
            title="User Management"
          />
          <SubMenuSidebar
            checkActive={_checkActive('special-hour') || _checkActive('standard-hour')}
            icon={<AiOutlineFieldTime className={styles['icon']} color="#9CA3AF" />}
            subMenu={[
              {
                checkActive: _checkActive('special-hour'),
                router: '/special-hour',
                title: 'Set Special Hours',
              },
              {
                checkActive: _checkActive('standard-hour'),
                router: '/standard-hour',
                title: 'Set Standard Hours',
              },
            ]}
            title="Operational Hour"
          />
        </div>
      </div>
      <div className={styles['profile']}>
        <img alt="default profile" src={IMAGES.DEFAULTPROFILE} />
        <div className={styles['title']}>
          <h6>{user ? user.full_name : ''}</h6>
          <p>Admin</p>
        </div>
        <ICONS.LOGOUT className="cursor-pointer" onClick={() => Logout()} />
      </div>
    </div>
  );
};

export default Sidebar;
