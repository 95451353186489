/* eslint-disable indent */
/* eslint-disable react/prop-types */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { ICONS } from '../../../configs';

const Table = ({
  thead,
  children,
  data,
  emptyMessage,
  slide,
  thead2,
  isSlide,
  setSlide,
  sortKey,
  sortOrder,
  setSortKey,
  setSortOrder,
}) => {
  const _sortData = ({ reverse, sortKey, tableData }) => {
    if (!sortKey) {
      return tableData;
    }

    const sortedData = data.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return tableData;
  };

  const SortButton = ({ sortOrder, columnKey, sortKey, onClick }) => {
    if (sortKey === columnKey && sortOrder === 'asc') {
      return <ICONS.ARROWUP className="ms-2 cursor-pointer" onClick={onClick} />;
    } else {
      return <ICONS.ARROWDOWN className="ms-2 cursor-pointer" onClick={onClick} />;
    }
  };

  const _sortedData = useCallback(
    () => _sortData({ reverse: sortOrder === 'desc', sortKey, tableData: data }),
    [data, sortKey, sortOrder]
  );

  const _changeSort = (key) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    if (key === 'aging_before_expire') {
      setSortKey('expired_at');
    } else {
      setSortKey(key);
    }
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {slide === 1
            ? thead.map((head, key) => {
                return (
                  <th key={key}>
                    {head.label}{' '}
                    {head.label && head.isSort && (
                      <SortButton
                        columnKey={head.key}
                        onClick={() => _changeSort(head.key)}
                        {...{ sortKey, sortOrder }}
                      />
                    )}
                  </th>
                );
              })
            : thead2.map((head, key) => {
                return (
                  <th key={key}>
                    {head.label}{' '}
                    {head.label && head.isSort && (
                      <SortButton
                        columnKey={head.key}
                        onClick={() => _changeSort(head.key)}
                        {...{ sortKey, sortOrder }}
                      />
                    )}
                  </th>
                );
              })}
          {isSlide && (
            <th>
              <div className="d-flex justify-content-end">
                <div
                  className={styles[slide === 1 ? 'btn-disabled' : 'btn-arrow']}
                  onClick={() => slide === 2 && setSlide(slide - 1)}>
                  <ICONS.ARROWLEFT />
                </div>
                <div
                  className={styles[slide === 2 ? 'btn-disabled' : 'btn-arrow']}
                  onClick={() => slide === 1 && setSlide(slide + 1)}>
                  <ICONS.ARROWRIGHT />
                </div>
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          _sortedData().map((val, idx) => children(val, idx))
        ) : (
          <tr>
            <td className="text-center" colSpan={thead.length}>
              {emptyMessage}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;

Table.defaultProps = {
  data: [],
  emptyMessage: 'Tidak ada data',
  head: [],
  isSlide: false,
  setSlide: null,
  slide: 1,
  thead: [],
  thead2: [],
};

Table.propTypes = {
  children: PropTypes.func.isRequired,
  data: PropTypes.array,
  emptyMessage: PropTypes.string,
  head: PropTypes.array,
  isSlide: PropTypes.bool,
  setSlide: PropTypes.func,
  slide: PropTypes.number,
  thead: PropTypes.array,
  thead2: PropTypes.array,
};
